import { useCallback, useEffect, useState } from "react";
import Loader from "../../../components/Loader/Loader";
import ClientBanner, { Banner, Client } from "./ClientBanner";
import { PlusOutlined } from "@ant-design/icons";
import api from "../../../api/api";
import { errorHandler } from "../../../utils/helper";
import { successNotification } from "../../../components/UI/Toast/Toast";


export const ClientBannerList: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false),
    [listOfClients, setListOfClients] = useState<Client[]>([]),
    [banners, setBanners] = useState<Banner[]>([]),
    fetchBanners = useCallback(() => {
      setLoading(true)
      api.settings.getBrokerBanner()
        .then((res) => {
          setBanners(res?.data?.results)
        })
        .catch((err) => {
          errorHandler(err?.response?.data)
        })
        .finally(() => setLoading(false))
    }, []),
    deleteBanner = useCallback((uuid: string) => {
      setLoading(true)
      api.settings.deleteBrokerBanner(uuid)
        .then((res) => {
          setBanners((prev) => prev.filter((item) => item.uuid !== uuid))
          successNotification('Banner deleted successfully')
        })
        .catch((err) => {
          errorHandler(err?.response?.data)
        })
        .finally(() => setLoading(false))
    }, []),
    updateBanner = useCallback((uuid: string, data: Banner) => {
      setLoading(true)
      const payload = {
        image: data?.image,
        mobile_image: data?.mobile_image,
        redirect_url: data?.redirect_url,
        title: data?.title,
        is_all: data?.is_all,
        organisations_uuid: (data?.organisations && !data.is_all) ? data?.organisations : [],
      }
      api.settings.updateBrokerBanner(uuid, payload)
        .then((res) => {
          setBanners((prev) => prev.map((item) => item.uuid === uuid ? res?.data : item))
          successNotification('Banner updated successfully')
        })
        .catch((err) => {
          errorHandler(err?.response?.data)
        })
        .finally(() => setLoading(false))
    }, []),
    addBanner = useCallback((data: Banner) => {
      setLoading(true)
      const payload = {
        image: data?.image,
        mobile_image: data?.mobile_image,
        redirect_url: data?.redirect_url,
        title: data?.title,
        is_all: data?.is_all,
        organisations_uuid: (data?.organisations && !data.is_all) ? data?.organisations : [],
      }
      api.settings.createBrokerBanner(payload)
        .then((res) => {
          setBanners((prev) => prev.map((item) => item.new ? res?.data : item))
          successNotification('Banner added successfully')
        })
        .catch((err) => {
          errorHandler(err?.response?.data)
        })
        .finally(() => setLoading(false))
    }, []),
    addNewBanner = useCallback(() => {
      setBanners((prev) => [...prev, { uuid: '', title: '', image: '', is_all: true, new: true }])
    }, []),
    fetchClients = useCallback(() => {
      api.claims_management
        .populateClaims({})
        .then((res) => {
          setListOfClients(res?.data?.organisations_list);
        })
        .catch((error) => {
          errorHandler(error?.response?.data);
        });
    }, []);

  useEffect(() => {
    fetchBanners();
  }, [fetchBanners])

  useEffect(() => {
    fetchClients();
  }, [fetchClients])


  return <Loader loading={loading}>
    <div className="mt-8">
      Customize the member dashboard/app with up to
      <strong>&nbsp;6 marketing banners. </strong>
      These will appear prominently at the top of the screen. The sequence of the banners will be the same as here.
    </div>
    {banners?.map((item: Banner, index: number) => {
      return <ClientBanner
        key={index}
        banner={item}
        index={index}
        deleteBanner={deleteBanner}
        updateBanner={updateBanner}
        addBanner={addBanner}
        clients={listOfClients}
      />
    })}
    <div className="cta mt-24" onClick={addNewBanner}>
      <PlusOutlined /> Add new banner
    </div>
  </Loader>
};