import { Col, Form, Input, Modal, Radio, Row, Select, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useCallback, useState } from 'react';
import Loader from '../../../components/Loader/Loader';
import { Button } from '../../../components/UI/Button/Button';
import { errorNotification } from '../../../components/UI/Toast/Toast';
import apiEndpoints from '../../../api/apiEndPoints';
import { errorHandler, getAccessToken } from '../../../utils/helper';
import Tooltip from '../../../components/UI/Tooltip/Tooltip';
import { ImagePreview } from '../../../components/UI/ImagePreview/ImagePreview';
import { BannerUploader } from '../../../components/UI/BannerUploader/BannerUploader';
import { EditOutlined } from '@ant-design/icons';

const { Title } = Typography;

export interface Banner {
  uuid: string;
  title: string;
  redirect_url?: string;
  image: string;
  mobile_image?: string;
  organisations?: Array<string>;
  is_all: boolean;
  new?: boolean;
}

export interface Client {
  uuid: string;
  brand_name: string;
}

interface Props {
  banner: Banner;
  index: number;
  deleteBanner: (id: string) => void;
  updateBanner: (id: string, data: Banner) => void;
  addBanner: (data: Banner) => void;
  clients: Array<Client>;
}

const ClientBanner = ({ banner, index, deleteBanner, updateBanner, addBanner, clients }: Props) => {
  const [form] = useForm();
  const [isAll, setIsAll] = useState<boolean>(banner?.is_all);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [desktopPreviewUrl, setDesktopPreviewUrl] = useState<string>(banner?.image || '');
  const [mobilePreviewUrl, setMobilePreviewUrl] = useState<string>(banner?.mobile_image || '');
  const [desktopFileList, setDesktopFileList] = useState<any[]>(
    banner?.image ? [{ url: banner.image, status: 'done' }] : []
  );
  const [mobileFileList, setMobileFileList] = useState<any[]>(
    banner?.mobile_image ? [{ url: banner.mobile_image, status: 'done' }] : []
  );
  const [desktopUploadReady, setDesktopUploadReady] = useState<boolean>(false);
  const [mobileUploadReady, setMobileUploadReady] = useState<boolean>(false);
  const [bannerTitle, setBannerTitle] = useState<string>(banner?.title || `Client Banner ${index + 1}`);

  const validateFormat = (file: File): boolean => {
    const isValidFormat = /\.(png|svg|jpg|jpeg|webp)$/i.test(file.name);
    if (!isValidFormat) {
      errorNotification('Please upload only PNG, SVG, JPG, JPEG or WEBP files');
    }
    return isValidFormat;
  };

  const createUploadProps = (isMobile: boolean) => ({
    name: 'image',
    accept: '.png, .svg, .jpg, .jpeg, .webp',
    multiple: false,
    customRequest: ({ file, onSuccess, onError }: any) => {
      if (!(isMobile ? mobileUploadReady : desktopUploadReady)) {
        return;
      }

      const formData = new FormData();
      formData.append('image', file);

      fetch(apiEndpoints.imageUpload, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
        },
        body: formData,
      })
        .then(res => res.json())
        .then(response => {
          onSuccess(response);
        })
        .catch(err => {
          onError(err);
        });
    },
    maxCount: 1,
    fileList: isMobile ? mobileFileList : desktopFileList,
    beforeUpload: (file: any) => {
      return validateFormat(file);
    },
    onChange(info: any) {
      const { status, response } = info.file;
      if (!(isMobile ? mobileUploadReady : desktopUploadReady)) {
        isMobile ? setMobileFileList([]) : setDesktopFileList([]);
        return;
      }

      isMobile ? setMobileFileList(info.fileList.slice(-1)) : setDesktopFileList(info.fileList.slice(-1));

      if (status === 'done') {
        isMobile ? setMobilePreviewUrl(response.url) : setDesktopPreviewUrl(response.url);
      } else if (status === 'error') {
        errorNotification(info?.file?.response?.error || 'Error: Please recheck the image and try again');
        isMobile ? setMobilePreviewUrl(banner?.mobile_image || '') : setDesktopPreviewUrl(banner?.image || '');
        isMobile ? setMobileFileList([]) : setDesktopFileList([]);
      }
    },
    onRemove: () => {
      if (isMobile) {
        setMobilePreviewUrl('');
        setMobileFileList([]);
        setMobileUploadReady(false);
      } else {
        setDesktopPreviewUrl(banner?.image || '');
        setDesktopFileList([]);
        setDesktopUploadReady(false);
      }
      return true;
    }
  });

  const onTitleChange = (value: string) => {
    if (!value) {
      errorNotification('Banner title cannot be empty');
      return false;
    }
    setBannerTitle(value);
    form.setFieldValue('title', value);
    return true;
  };

  const submitHandler = useCallback(() => {
    setBtnLoading(true);
    form.validateFields()
      .then((values) => {
        const data = { 
          ...values, 
          title: bannerTitle,
          image: values.image || desktopPreviewUrl,
          mobile_image: values.mobile_image || mobilePreviewUrl
        };
        !banner?.new ? updateBanner(banner.uuid, { ...data, uuid: banner.uuid }) : addBanner(data);
      })
      .catch((err) => {
        errorHandler(err?.response?.data);
      })
      .finally(() => {
        setBtnLoading(false);
      });
  }, [banner, updateBanner, addBanner, bannerTitle, desktopPreviewUrl]);

  const deleteHandler = useCallback((uuid: string) => {
    Modal.confirm({
      title: 'This action cannot be reversed',
      content: "Are you sure you want to delete this banner?",
      okText: 'Yes, I\'m sure',
      cancelText: 'No',
      onOk: () => {
        deleteBanner(uuid);
      },
    });
  }, []);

  return (
    <Loader>
      <Form 
        form={form} 
        initialValues={{ 
          ...banner, 
          image: banner?.image || desktopPreviewUrl, 
          mobile_image: banner?.mobile_image || mobilePreviewUrl,
          title: bannerTitle,
          is_all: banner?.is_all ?? true
        }}
      >
        <div 
          className="banner-title-container"
          style={{ 
            display: 'flex', 
            alignItems: 'center',
            marginTop: '20px',
            marginBottom: '24px',
            paddingLeft: '1px',
            width: '100%'
          }}
        >
          <Title 
            level={5} 
            style={{ 
              margin: 0,
              fontWeight: 'normal',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              color: '#000000D9',
              width: '100%',
              maxWidth: '400px'
            }}
            editable={{ 
              onChange: onTitleChange,
              tooltip: 'Edit banner name',
              triggerType: ['icon'],
              maxLength: 50,
              icon: <EditOutlined style={{ fontSize: '16px', color: '#1890ff', marginLeft: '4px' }} />,
              enterIcon: null,
              autoSize: { maxRows: 1 }
            }}
          >
            {bannerTitle}
          </Title>
        </div>

        <style>
          {`
            .banner-title-container .ant-typography-edit-content {
              left: 0;
              margin-top: -5px;
              max-width: 400px;
            }
            .banner-title-container .ant-input {
              padding: 4px 11px;
              border: 1px solid #d9d9d9;
              border-radius: 2px;
              height: 32px;
            }
            .banner-title-container .ant-input:hover,
            .banner-title-container .ant-input:focus {
              border-color: #40a9ff;
              box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
            }
          `}
        </style>

        <Row gutter={24}>
          <Col span={12}>
            <BannerUploader
              aspect={6}
              uploadProps={createUploadProps(false)}
              onModalCancel={() => {
                setDesktopUploadReady(false);
                setDesktopFileList([]);
              }}
              beforeCrop={(file) => {
                setDesktopUploadReady(false);
                return validateFormat(file);
              }}
              onModalOk={() => setDesktopUploadReady(true)}
              form={form}
            />
          </Col>
          <Col span={12}>
            <BannerUploader
              aspect={2}
              isMobile
              uploadProps={createUploadProps(true)}
              onModalCancel={() => {
                setMobileUploadReady(false);
                setMobileFileList([]);
              }}
              beforeCrop={(file) => {
                setMobileUploadReady(false);
                return validateFormat(file);
              }}
              onModalOk={() => setMobileUploadReady(true)}
              form={form}
            />
          </Col>
        </Row>

        <ImagePreview 
          imageUrl={desktopPreviewUrl}
          mobileImageUrl={mobilePreviewUrl}
          aspectRatio={6}
          isDefault={!desktopPreviewUrl} 
        />

        <Form.Item
          label={<span>Redirect Link <Tooltip message="Banner would redirect to the link added here" /></span>}
          labelCol={{ span: 24 }}
          name="redirect_url"
          validateFirst
          className="label margin-top"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={<span>Clients <Tooltip message="Select the clients for which this banner is to be displayed" /></span>}
          labelCol={{ span: 24 }}
          name="is_all"
          validateFirst
          className="label margin-top"
        >
          <Radio.Group onChange={(e) => setIsAll(e.target.value)}>
            <Radio value={true}>All Clients</Radio>
            <Radio value={false}>Specific Clients</Radio>
          </Radio.Group>
        </Form.Item>

        {!isAll && <Form.Item
          label={<span>Clients <Tooltip message="Select the clients for which this banner is to be displayed" /></span>}
          labelCol={{ span: 24 }}
          name="organisations"
          validateFirst
          className="label margin-top"
        >
          <Select 
            mode="multiple" 
            placeholder="Select Client"
            showSearch
            filterOption={(input, option) => 
              (option?.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {clients?.map((item: Client) => {
              return <Select.Option key={item?.uuid} value={item?.uuid}>{item?.brand_name}</Select.Option>;
            })}
          </Select>
        </Form.Item>}

        <Row gutter={16} justify="start">
          <Col>
            <Button type="primary" loading={btnLoading} onClick={submitHandler}>Save</Button>
          </Col>
          {!banner?.new && <Col>
            <Button type="secondary" loading={btnLoading} onClick={() => deleteHandler(banner?.uuid)}>Delete</Button>
          </Col>}
        </Row>

        <Form.Item name="title" hidden>
          <Input />
        </Form.Item>
      </Form>
    </Loader>
  );
};

export default ClientBanner;
