import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import Loader from '../../../components/Loader/Loader';
import { TabButton } from '../../../components/UI/Button/Button';
import Breadcrumbs from '../../../components/header/Breadcrumbs';
import Notifications from './Notifications';
import { ClientBannerList } from './ClientBannerList';
// import './_notifications.scss';

const Settings: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false),
        [searchParams, setSearchParams] = useSearchParams(),
        [activeTab, setActiveTab] = useState(searchParams.get('tab') || 'notifications'),
        tabChangeHandler = (tab: string) => {
            setActiveTab(tab);
            setSearchParams({ tab: tab }, { replace: true });
        },
        tabs = {
            activeTab: activeTab,
            tabChangeHandler: tabChangeHandler,
            list: [
                {
                    key: 'notifications',
                    text: 'Notifications',
                    notification: false,
                },
                // {
                //     key: 'privacy_and_support',
                //     text: 'Privacy and Support',
                //     notification: false,
                // },
                {
                    key: 'client_banner',
                    text: 'Custom Banners',
                    notification: false,
                },
            ],
        };

    return (
        <Loader
            loading={loading}
            // screen='settings'
        >
            <Breadcrumbs />
            <div className="table-cta-tabs">
                {tabs.list.map((item: any, index: any) => {
                    return (
                        <TabButton
                            key={index}
                            active={tabs.activeTab === item.key}
                            onClick={() => tabs.tabChangeHandler(item.key)}
                        >
                            {item.text} {item.notification && <span className="actions-pending-symbol">•</span>}
                        </TabButton>
                    );
                })}
            </div>

            {tabs.activeTab === 'notifications' && <Notifications />}
            {tabs.activeTab === 'client_banner' && <ClientBannerList />}

        </Loader>
    );
};

export default Settings;
