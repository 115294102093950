import { Form, FormInstance } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import ImgCrop from 'antd-img-crop';
import { UploadProps } from 'antd/lib/upload';

interface BannerUploaderProps {
  aspect: number;
  isMobile?: boolean;
  uploadProps: UploadProps;
  onModalCancel: () => void;
  onModalOk: () => void;
  beforeCrop: (file: File) => boolean;
  form: FormInstance;
}

export const BannerUploader = ({
  aspect,
  isMobile = false,
  uploadProps,
  onModalCancel,
  onModalOk,
  beforeCrop,
  form
}: BannerUploaderProps) => {
  const deviceType = isMobile ? 'mobile' : 'desktop';
  const recommendedSize = isMobile ? '320×160px (2:1)' : '1920×320px (6:1)';
  const fieldName = isMobile ? 'mobile_image' : 'image';

  return (
    <Form.Item
      name={fieldName}
      valuePropName="image"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      validateTrigger={['onChange', 'onBlur']}
      rules={isMobile ? [] : [{ 
        required: true, 
        message: "Please upload a desktop banner image",
        validator: (_, value) => {
          const fileList = uploadProps.fileList || [];
          if (value || fileList.length > 0) {
            return Promise.resolve();
          }
          return Promise.reject(new Error('Please upload a desktop banner image'));
        }
      }]}
      label={<>
        {isMobile ? 'Mobile & Tablet Banner Image (Optional)' : 'Desktop Banner Image'}
      </>}
    >
      <ImgCrop 
        aspect={aspect}
        quality={1}
        modalTitle={`Crop ${deviceType} Banner`}
        modalWidth={800}
        showGrid
        showReset
        onModalCancel={() => {
          onModalCancel();
          form.setFieldValue(fieldName, undefined);
        }}
        beforeCrop={beforeCrop}
        onModalOk={onModalOk}
      >
        <Dragger 
          {...uploadProps} 
          listType="picture"
          onChange={(info) => {
            uploadProps.onChange?.(info);
            if (info.file.status === 'done' && info.file.response?.url) {
              form.setFieldValue(fieldName, info.file.response.url);
            }
          }}
        >
          <p className="ant-upload-text" style={{ margin: "24px 0" }}>
            Drag and drop your {deviceType} banner here
            <br /> or click here to upload
            <br />
            <span style={{ fontSize: '12px', color: '#666', fontStyle: 'italic' }}>
              Recommended size: {recommendedSize}
              <br />
              Accepted formats: PNG, SVG, JPG, JPEG, WEBP
            </span>
          </p>
        </Dragger>
      </ImgCrop>
    </Form.Item>
  );
}; 